import { useParams } from "react-router-dom";
import { LoadGoodsFeature } from "../../../libs/loads/feature/src/lib/load-goods-feature/load-goods-feature";

export const LoadGoodsPage = () => {
	const { id, uniqueId } = useParams();
	return (
		<>
			<title>Load {uniqueId ?? id} - Goods</title>
			<LoadGoodsFeature loadId={id} />
		</>
	);
};
