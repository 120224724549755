import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { type LoadFormObject, LoadSchema } from "../Load";
import { DFlex } from "../display/DFlex";
import { useOnAddLocation } from "../helpersReact";
import {
	GenericForm,
	IDateTime,
	IDropzone,
	ISelect,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";

type LoadFormProps = {
	defaultValues?: Partial<LoadFormObject>;
	onSubmit: (data: LoadFormObject) => void;
	lLoadTypes: LoadOptionsFn;
	lUsers: LoadOptionsFn;
	lLocations: LoadOptionsFn;
	lTrailers: LoadOptionsFn;
	onAddLocation?: (callback: (value: number) => void) => void;
};

export const LoadForm = ({
	defaultValues,
	onSubmit,
	lLoadTypes,
	lLocations,
	lUsers,
	lTrailers,
	onAddLocation,
}: LoadFormProps) => {
	const form = useForm<LoadFormObject>({
		resolver: yupResolver(LoadSchema),
		defaultValues,
	});

	const startLocationButton = useOnAddLocation<LoadFormObject>(
		"startLocationId",
		form.setValue,
		onAddLocation,
	);

	const endLocationButton = useOnAddLocation<LoadFormObject>(
		"endLocationId",
		form.setValue,
		onAddLocation,
	);
	return (
		<GenericForm
			form={form}
			schema={LoadSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<ISelect n="loadTypeId" l={lLoadTypes} />
					<ISelect
						n="startLocationId"
						l={lLocations}
						label={
							<>
								Start Location
								{startLocationButton}
							</>
						}
					/>
					<IDateTime n="startDate" />
					<ISelect
						n="endLocationId"
						l={lLocations}
						label={
							<>
								End Location
								{endLocationButton}
							</>
						}
					/>
					<IDateTime n="endDate" />
				</div>
				<div>
					<ISelect n="trailerId" l={lTrailers} />
					<ISelect n="assignedTo" l={lUsers} />
					<ITextArea n="notes" />
					<IDropzone n="documents" />
				</div>
			</DFlex>
		</GenericForm>
	);
};
