import { Button } from "@progress/kendo-react-buttons";
import {
	boxSizingIcon,
	chevronDownIcon,
	chevronUpIcon,
	dollarIcon,
	eyeIcon,
	fileConfigIcon,
	fileIcon,
	pencilIcon,
	textboxIcon,
} from "@progress/kendo-svg-icons";
import { Link } from "react-router-dom";
import { DetailsCard } from "../../DetailsCard";
import { DetailsDisplayLocation } from "../../DetailsDisplayLocation";
import { DetailsInformationCard } from "../../DetailsInformationCard";
import type { JobDetailsResponse } from "../../api/JobApi";
import { DGrid } from "../../display/DGrid";
import { toCurrency } from "../../helpers";

type JobDetailsInformationProps = {
	data: JobDetailsResponse;
	onEditClick: () => void;
	onViewDocumentsClick: () => void;
};
export const JobDetailsInformation = ({
	data,
	onEditClick,
	onViewDocumentsClick,
}: JobDetailsInformationProps) => (
	<DetailsCard
		title="Job Information"
		buttons={
			<>
				<Button svgIcon={eyeIcon} onClick={onViewDocumentsClick}>
					Documents{" "}
					{data.documents.length ? `(${data.documents.length})` : undefined}
				</Button>
				<Button svgIcon={pencilIcon} themeColor="primary" onClick={onEditClick}>
					Edit Info
				</Button>
			</>
		}
	>
		<DGrid columns="repeat(2, 1fr)" gap="8px">
			<DetailsInformationCard
				icon={dollarIcon}
				title="Customer"
				value={data.customerName}
			/>
			<DetailsInformationCard
				icon={fileConfigIcon}
				title="Job Type"
				value={data.typeName}
			/>
			<DetailsInformationCard
				icon={dollarIcon}
				title="Price"
				value={data.price ? toCurrency(data.price, data.currencyCode) : null}
			/>
			<DetailsInformationCard
				icon={eyeIcon}
				title="Operator"
				value={data.assignedTo}
			/>
			<DetailsInformationCard
				icon={fileIcon}
				title="Purchase Order"
				value={data.purchaseOrderNumber}
			/>
			<DetailsInformationCard
				icon={dollarIcon}
				title="Total Cost of Legs"
				value={data.cost ? toCurrency(data.cost, data.currencyCode) : null}
			/>
			<DetailsInformationCard
				icon={dollarIcon}
				title="VAT"
				value={data.useVat ? "Yes" : null}
			/>
			<DetailsInformationCard
				icon={dollarIcon}
				title="VAT Rate"
				value={data.vatRate ? `${data.vatRate}%` : null}
			/>
			<DetailsInformationCard
				icon={dollarIcon}
				title="Profit"
				value={data.profitPercent ? `${data.profitPercent}%` : null}
			/>
			<DetailsInformationCard
				icon={boxSizingIcon}
				title="Loads"
				value={
					data.loads.length
						? data.loads.map((load) => (
								<Link to={`/loads/${load.id}`} key={load.id}>
									{load.uniqueId}
								</Link>
							))
						: null
				}
			/>
			<DetailsInformationCard
				icon={chevronUpIcon}
				title="Start Location"
				value={<DetailsDisplayLocation {...data.startLocation} />}
			/>
			<DetailsInformationCard
				icon={chevronDownIcon}
				title="End Location"
				value={<DetailsDisplayLocation {...data.endLocation} />}
			/>
			<DetailsInformationCard
				icon={textboxIcon}
				title="Notes"
				value={data.notes ?? "-"}
			/>
		</DGrid>
	</DetailsCard>
);
