import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ErrorCode } from "../../libs/common/models/src/lib/enums/error-codes.enum";
import {
	useAppDispatch,
	useAppSelector,
} from "../../libs/common/stores/src/lib/utils";
import { CelerumNotificationsGroup } from "../../libs/common/ui/src/lib/components/celerum-notifications-group/celerum-notifications-group.component";
import { resetNotificationAction } from "../../libs/user-interface/data-access/src/lib/user-interface.slice";
import { resetUsersErrorCode } from "../../libs/users/data-access/src/lib/users.slice";

export const CelerumNotificationGroup = () => {
	const location = useLocation();
	const resetResponseTimeout = useRef<Timer>(null);

	const dispatch = useAppDispatch();

	const { message, notificationType } = useAppSelector(
		(state) => state.userInterface,
	);
	const { errorCode: usersErrorCode } = useAppSelector((state) => state.users);

	const displayNotificationGroupByErrorCode = () => {
		/** This method's purpose is to not display the notification bar, in case of a specific error. */
		return usersErrorCode !== ErrorCode.INVALID_MODEL;
	};

	useEffect(() => {
		location;
		dispatch(resetNotificationAction());
	}, [location, dispatch]);

	useEffect(() => {
		if (notificationType !== null) {
			resetResponseTimeout.current = setTimeout(() => {
				dispatch(resetNotificationAction());
				dispatch(resetUsersErrorCode());
			}, 5000);
		}

		return () => {
			resetResponseTimeout.current &&
				clearTimeout(resetResponseTimeout.current);
		};
	}, [notificationType, dispatch]);

	return displayNotificationGroupByErrorCode() ? (
		<CelerumNotificationsGroup type={notificationType} message={message} />
	) : null;
};
