import { Button } from "@progress/kendo-react-buttons";
import { checkIcon } from "@progress/kendo-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { GoodGridDataResponse } from "../api/JobApi";
import { GenericDataGrid } from "../grids/GenericDataGrid";
import {
	type TypedGridColumnProps,
	jobApi,
	jobStatusNamesAndColors,
	uncheckGridSelection,
} from "../helpers";
import { useDialog } from "../helpersReact";

const defaultColumns: TypedGridColumnProps<GoodGridDataResponse>[] = [
	{ field: "uniqueId", title: "Unique ID" },
	{ field: "jobTypeName", title: "Job Type" },
	{ field: "customerName", title: "Customer" },
	{ field: "constraintNames", title: "Constraints" },
	{ field: "name", title: "Name" },
	{ field: "description", title: "Description" },
	{ field: "quantity", title: "Quantity" },
	{ field: "weight", title: "Weight" },
	{ field: "collectionLocationName", title: "Collection Location" },
	{ field: "collectionDate", title: "Collection Date" },
	{ field: "deliveryLocationName", title: "Delivery Location" },
	{ field: "deliveryDate", title: "Delivery Date" },
	{ field: "jobStatusName", title: "Job Status" },
];

type GoodsGridWithDTOProps = {
	onGoodsSelected?: (goodIds: number[]) => unknown;
};
const GoodsGridWithDTO = ({ onGoodsSelected }: GoodsGridWithDTOProps) => {
	const [selectedGoods, setSelectedGoods] = useState<Set<number>>();
	const [loading, setLoading] = useState(false);
	const data = useQuery({
		queryKey: ["goods"],
		queryFn: () => jobApi.bff.bffGoodGridDataList().then((x) => x.data),
		initialData: [],
	});
	useEffect(() => {
		// ugly hack to uncheck the grid selection when the data is fetched after mount
		if (data.isFetchedAfterMount) {
			setLoading(false);
			uncheckGridSelection();
		}
	}, [data.isFetchedAfterMount]);
	if (!data.isFetchedAfterMount) return "Loading...";
	return (
		<GenericDataGrid
			defaultColumns={defaultColumns}
			name="Goods"
			data={data.data.map((item) => ({
				...item,
				jobStatusName: jobStatusNamesAndColors[item.jobStatus]?.name ?? "",
			}))}
			refresh={data.refetch}
			loading={data.isFetching}
			onSelectionChange={setSelectedGoods}
			extraButtons={
				<Button
					themeColor="primary"
					svgIcon={checkIcon}
					onClick={async () => {
						setLoading(true);
						await onGoodsSelected?.([...(selectedGoods ?? [])]);
						setLoading(false);
					}}
					disabled={selectedGoods?.size === 0 || loading}
				>
					Add to Load
				</Button>
			}
		/>
	);
};

export const useGoodsSelectionDialog = () => {
	const [props, setProps] = useState<GoodsGridWithDTOProps>();
	const [_toggleDialog, dialog] = useDialog(
		props && <GoodsGridWithDTO {...props} />,
		"Goods Selection",
	);

	const toggleDialog = (props?: GoodsGridWithDTOProps) => {
		setProps(props);
		_toggleDialog(props != null);
	};

	return [toggleDialog, dialog] as const;
};
