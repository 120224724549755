import { type Align, Popup } from "@progress/kendo-react-popup";
import cls from "classnames";
import styles from "./celerum-popup.module.css";

interface CelerumPopupProps {
	show: boolean | undefined;
	children: React.ReactNode;
	anchor: React.RefObject<HTMLElement | null>;
	appendTo?: HTMLElement | null;
	popupAlign?: Align;
	className?: string;
}

export const CelerumPopup = ({
	show,
	children,
	appendTo,
	anchor,
	popupAlign = { horizontal: "center", vertical: "top" },
	className,
}: CelerumPopupProps) => (
	<Popup
		anchor={anchor.current}
		appendTo={appendTo}
		show={show}
		popupClass={cls(styles.container, className)}
		popupAlign={popupAlign}
	>
		{children}
	</Popup>
);
