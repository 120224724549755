import { ForgotPassword } from "../../../libs/authentication/feature/src/lib/forgot-password/forgot-password.component";
import styles from "./forgot-password.module.css";

export const ForgotPasswordPage = () => (
	<div className={styles.root}>
		<title>Forgotten Password</title>
		<div className={styles.leftSide} />
		<div className={styles.rightSide}>
			<ForgotPassword />
		</div>
	</div>
);
