import dayjs from "dayjs";
import { DFlex } from "./display/DFlex";

type DetailsDisplayLocationProps = {
	name?: string | null;
	address?: string | null;
	date?: Date | string | null;
};
export const DetailsDisplayLocation = ({
	name,
	address,
	date,
}: DetailsDisplayLocationProps) => (
	<DFlex column noGap>
		<h3>{name ?? "-"}</h3>
		<h4>{address ?? "-"}</h4>
		<h5>{date ? dayjs(date).format("DD/MM/YYYY HH:mm") : "-"}</h5>
	</DFlex>
);
