import type { TextBoxHandle } from "@progress/kendo-react-inputs";
import type { RefObject } from "react";
import type { IFilterItem } from "../../../../models/src/lib/interfaces/filter.interface";

export const onIdFilterChange = (
	column: string,
	value: (string | number)[],
	idsFilter: IFilterItem[],
	setIdsFilter: (array: IFilterItem[]) => void,
) => {
	const result = [];

	if (!value.length) {
		setIdsFilter([]);
		return;
	}

	if (!idsFilter.find((item: IFilterItem) => item.id === column)) {
		setIdsFilter([...idsFilter, { id: column, value: value }]);
		return;
	}

	for (const type of idsFilter) {
		if (type.id === column && value?.length) {
			result.push({ id: column, value });
		} else {
			result.push(type);
		}
	}

	setIdsFilter(result);
};

export const handleSearchInputClear = (
	setSearchValue: (value: string) => void,
	inputRef: RefObject<TextBoxHandle | null>,
) => {
	setSearchValue("");
	if (inputRef.current?.element) {
		inputRef.current.element.value = "";
	}
};
